import React,{ useMemo, useState, useContext, createContext, useEffect } from 'react'
import './index.scss'

const MenuContextData = createContext({
    onChange: ()=>{},
    status: false,
});


function CollaspeBox({children,open,block,className,onChange}){
    const [status,setStatus] = useState(open||false)

    useEffect(()=>{
        open && setStatus(open)
    },[open])

    const change = (event)=>{
        
        if(event.target?.hasAttribute('data-open')) return;
        setStatus(!status);
        onChange && onChange(!status)
    }

    return <>
        <MenuContextData.Provider value={{ onChange: change, status }}>
            {
                block
                ? <div className={className}>{children}</div>
                : children
            }
        </MenuContextData.Provider>
    </>
}


function Title({children,iconFixed,className}){

    const { onChange, status} = useContext(MenuContextData)

    return (
        <div className={`menus_title flex align_center ${className}`} onClick={onChange}>
            {
                iconFixed==='before' ? 
                <span className={`flex align_center icon p-icon-Down`} style={{"transform": status ? "rotate(180deg)":""}}></span> 
                : ''
            }
            <div className="flex_1">
                {children}
            </div>
            {
                !iconFixed || iconFixed==='after' ? 
                <span className={`flex align_center icon p-icon-Down`} style={{"transform": status ? "rotate(180deg)":""}}></span> 
                : ''
            }
        </div>
    )
}

function Box({children,className,maxHeight}){
    const { status} = useContext(MenuContextData)
    return (
        <div className={`menus_box ${className} ${status?"":'close'}`} style={(status && maxHeight) ? {maxHeight} : {}}>
            {children}
        </div>
    )
}




CollaspeBox.Title = Title;
CollaspeBox.Box = Box;

export default CollaspeBox;
