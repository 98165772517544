import { DropBox } from '../components/ui'
import './layout.scss'

export default function CommonHeader(){
    return (
        <div className='headerModule p_fixed flex align_center justify_between bd_b bd_a_c_6_1'>
            <img src='./images/logo/logo_new.png' style={{height:"42px"}} />

            <div className='flex align_center gap_48 sd_hide'>
                <a className='fs_16 hover_c_1' href='https://docs.paddlefi.com/' target='_blank'>DOCS</a>
                <a className='fs_16 hover_c_1' href='https://twitter.com/PaddleFi' target='_blank'>TWITTER</a>
                {/* <a className='fs_16 hover_c_1' href="https://community.paddlefi.com/" target="_blank">COMMUNITY</a> */}
                <a className='fs_16 hover_c_1 text_upper' href='https://t.me/PaddleFi' target='_blank'>Telegram</a>
                
                <div className='flex gap_12'>
                    <a className="flex align_center b_g_1 br_12 px_12 py_8 cursor text_black hover_s_3" href='https://dapp.paddlefi.com' target='_blank'>
                        <span className='fs_14 fw_600'>LAUNCH DAPP</span>
                    </a>
                    <a className="flex align_center b_g_1 br_12 px_12 py_8 cursor text_black hover_s_3" href='https://battle.paddlefi.com' target='_blank'>
                        <span className='fs_14 fw_600'>LAUNCH BATTLE</span>
                    </a>
                    <a className="flex align_center b_g_1 br_12 px_12 py_8 cursor text_black hover_s_3" href='https://token.paddlefi.com' target='_blank'>
                        <span className='fs_14 fw_600'>LAUNCH PORTAL</span>
                    </a>
                </div>
            </div>

            <div className='flex align_center gap_12 ld_hide md_hide'>
                <DropBox position="right" title={
                    <div className="flex align_center b_g_1 br_12 px_12 py_8 cursor">
                        <span className='fs_14 fw_600'>PRODUCTS</span>
                    </div>
                }>
                    <div className='ws_148 flex column align_end gap_12 mt_16 br_12 align_center'>
                        <a className="flex align_center b_g_1 br_12 px_12 py_8 cursor" href='https://dapp.paddlefi.com' target='_blank'>
                            <span className='fs_14 fw_600 text_black'>LAUNCH DAPP</span>
                            {/* <span className="icon p-icon-more fs_20"></span> */}
                        </a>
                        <a className="flex align_center b_g_1 br_12 px_12 py_8 cursor" href='https://battle.paddlefi.com' target='_blank'>
                            <span className='fs_14 fw_600 text_black'>LAUNCH BATTLE</span>
                            {/* <span className="icon p-icon-more fs_20"></span> */}
                        </a>
                        <a className="flex align_center b_g_1 br_12 px_12 py_8 cursor" href='https://token.paddlefi.com' target='_blank'>
                            <span className='fs_14 fw_600 text_black'>LAUNCH PORTAL</span>
                            {/* <span className="icon p-icon-more fs_20"></span> */}
                        </a>
                    </div>
                </DropBox>
                
            </div>
        </div>
    )
}