import React,{ useEffect, useState, useMemo, useRef, useContext, createContext } from 'react'
import './index.scss'

const SelectContextData = createContext({
    onChange: ()=>{},
    selected: {},
});



const SelectBox = (props)=>{

    const { mustSelectOne=true } = props
    const listRef = useRef()
    const [selected,setSelected]=useState({})
    const [status,setStatus] = useState(false);

    useEffect(()=>{
        setSelected(getDefaultValue(props.value) || {});
    },[props.value,props.children,mustSelectOne])

    // bug ,
    const getDefaultValue = (value)=>{
        
        const list = props.children

        if(!Array.isArray(list)){
            return list.props;
        }

        if(Array.isArray(list) && list.length===0) return;

        if(value===null || value===undefined){
            return mustSelectOne ? list[0].props : null;
        }

        let obj = null;
        list.forEach(val=>{
            if(val.props.value===value){
                obj = {
                    ...val.props
                }
            }
        });
        return obj;
    }


    const onChange = (item)=>{
        if(props.disabled) return
        setStatus(false);
        setSelected({...item})
        props.onChange && props.onChange(item.value,item);
    }

    const click = (e)=>{
        !status && props.onClick && props.onClick()
        setStatus(!status,e)
    }

    const getBoard = useMemo(()=>{
        let reDir = props.dir === 'right' ? {right:'0'} : {}
        if(status){
            return {opacity:1,maxHeight:'200px',top:'110%',...reDir}
        }else{
            return {opacity:0,maxHeight:0,top:0,...reDir}
        }

    },[status])


    return (
        <SelectContextData.Provider value={{ onChange, selected }}>
            <div className={`selectBox fs_14 w100`} tabIndex="1" onBlur={()=>setStatus(false)}>
                <div className={`flex align_center justify_between cursor br_8 fs_14 ${props.nopadding?"":"pa_12"} ${props.noborder ? '': 'bd'} ${props.className}`} 
                    onClick={(e)=>click(e)} >
                    {props.addonBefore}
                    <EmptyWrap type={!!props.addonBefore}>
                        <div className={`${props.applyType || ''}`}>
                            { !props.simple ? selected.children : ''}
                        </div>
                        <div className={`selectBoxIcon text_3 icon ${props.icon || 'p-icon-upAndDown'} ${props.iconSize || 'fs_24'}`}></div>
                    </EmptyWrap>
                </div>
                <div className={`selectBoxList shadow_3 b_g_3 mt_4 br_8 ${props.simple?"":"w100"}`} style={getBoard}>
                    <div className="selectBoxIn py_12" ref={listRef}>
                        {props.children}
                    </div>
                </div>
            </div>
        </SelectContextData.Provider>

    )
}


function EmptyWrap({type,children}){
    return type?<div className='flex align_center justify_between'>{children}</div>:children
}




// option
export const Option = (props)=>{

    const {value,className,children} = props
    const { onChange, selected} = useContext(SelectContextData)

    return (
        <div
            onClick={()=>{!props.disabled && onChange(props)}}
            className={`selectOption text_2 pl_12 py_4 ${selected.value === value ? "active": ""} ${className || ""}`}>
                {children}
        </div>
    )
}





SelectBox.Option = Option;

export default SelectBox;




