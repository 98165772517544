import { useEffect, useRef, useState } from 'react';
import './index.scss'

export function DropBox(props){

    const { title, position='',effect='effect_down',children,className,wrapClassName,trigger='hover' } = props
    const [status,setStatus] = useState(false)

    const changeStatus = (event)=>{
        event.stopPropagation();
        // console.log(event)
        trigger==='click' && setStatus(!status)
    }

    return (
        <div className={`dropMenuModule h100 p_relative ${trigger==='hover' ?  'hover' : (status?'click':'')} ${wrapClassName||''}`} onClick={changeStatus}>
            {title}
            {
                children &&
                <div className={`dropMenu pt_4 ${effect}`} dataset="dropMenu">
                    <div className={`dropMenuList p_relative br_12 ${position} ${className}`}>
                        {children}
                    </div>
                </div>
            }
        </div>
    )
}


export default DropBox;

